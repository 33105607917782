
header {
    padding-top: var(--page-margin-top);
    padding-left: var(--page-margin-x);
    padding-right: var(--page-margin-x);
}


#brand {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    color: var(--color-text);
    text-decoration: none;
    font-size: 2rem;
}

#brand #brand-name {
    display: inline-block;
}

#brand #brand-name h1 {
    margin: 0;
    font-size: 2.1rem;
    letter-spacing: .05rem;
}

#brand #brand-name h4 {
    margin: 0;
    font-size: 0.825rem;
}

nav-spacer {
    flex: 1;
}

header ul {
    padding: 0;
    margin: 0;
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-direction: row;
    gap: 3rem;
}

header nav ul li {
    display: inline-block;
}

header nav ul li a {
    color: var(--color-text);
    text-decoration: none;
    font-size: 1.1rem;
}

header nav ul li a:visited {
    color: var(--color-text);
}

header nav ul li a:hover {
    color: var(--color-menu-item-highlight);
}

#site-search-input {
    font-size: 1rem;
    padding: 0.5rem;
    border: 1px solid #aaaaaa;
    border-radius: 0.5rem;
}

#site-search-input:focus {
    outline: none;
    border: 1px solid #5a86b4;
    box-shadow: 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
